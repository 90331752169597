<script>
import MainCard from '@/components/MainCard';
import Button from '@/components/Button';
// import MainCarousel from '@/components/Banners/MainCarousel';
// import Tab from '@/components/Tabs/Tab';
// import Tabs from '@/components/Tabs/Tabs';
// import CryptoChart from '@/components/CryptoChart';
import cryptoDataMilk2 from '@/assets/data/dataMilk2.json';
import cryptoDataShake from '@/assets/data/dataShake.json';

export default {
  data() {
    return {
      cryptoDataMilk2,
      cryptoDataShake,
    };
  },
  name: 'Index',
  components: {
    // MainCarousel,
    MainCard,
    Button,
    // Tab,
    // Tabs,
    // CryptoChart,
  },
  computed: {
    chainId() {
      return this.$store.state.User.chainId;
    },
  },
};
</script>

<template>
  <div class="main-wrap index-page--small">
    <!--      <div class="carousel">-->
    <!--        <MainCarousel class="carousel&#45;&#45;small" />-->
    <!--      </div>-->
    <!--      <div class="chart">-->
    <!--        <template>-->
    <!--            <tabs>-->
    <!--              <tab title="MILK 2" subtitle="0.0103$" icon="41.8%" image="milk">-->
    <!--                <crypto-chart :crypto-data="cryptoDataMilk2" name="milk"></crypto-chart>-->
    <!--              </tab>-->
    <!--              <tab title="SHAKE" subtitle="208.09$" icon="5.26%" image="shake">-->
    <!--                <crypto-chart :crypto-data="cryptoDataShake" name="shake"></crypto-chart>-->
    <!--              </tab>-->
    <!--            </tabs>-->
    <!--        </template>-->
    <!--      </div>-->
    <div class="special">
      <MainCard class="index-page-card--special index-page-card--erc20">
        <template #card-icon>
          <img src="@/assets/img/index-page/erc20-gen-banner.png" alt="erc20 generator"
               class="index-page-card__icon__img">
        </template>
        <template #card-title>
          {{ $t('main.cards.erc20-generator.title') }}
        </template>
        <template #card-info>{{ $t('main.cards.erc20-generator.description') }}</template>
        <template #card-button>
          <Button v-if="$chain.is('huobi')" disabled>
            {{ $t('main.soon') }}
          </Button>
          <Button href="https://minter.spaceswap.app/">
            {{ $t('main.cards.erc20-generator.button') }}
          </Button>
        </template>
      </MainCard>
    </div>
    <div class="cards">
      <div class="card">
        <MainCard class="index-page-card--small index-page-card--interstellar">
          <template #card-title>{{ $t('main.cards.interstellar.title') }}</template>
          <template #card-info>{{ $t('main.cards.interstellar.description') }}</template>
          <template #card-button>
            <Button v-if="$chain.is('huobi')" disabled>
              {{ $t('main.soon') }}
            </Button>
            <Button to="pools/interstellar" v-else>
              {{ $t('main.cards.interstellar.button') }}
            </Button>
          </template>
        </MainCard>
      </div>
      <div class="card">
        <MainCard class="index-page-card--small index-page-card--booster">
          <template #card-title>
            {{ $t('main.cards.booster.title') }}
          </template>
          <template #card-info>{{ $t('main.cards.booster.description') }}</template>
          <template #card-button>
            <Button v-if="$chain.is('huobi')" disabled>
              {{ $t('main.soon') }}
            </Button>
            <Button to="booster" v-else :disabled="$chain.is('avalanche')">
              {{ $t('main.cards.booster.button') }}
            </Button>
          </template>
        </MainCard>
      </div>
      <div class="card">
        <MainCard class="index-page-card--small index-page-card--bridge disabled">
          <template #card-title>{{ $t('main.cards.bridge.title') }}</template>
          <template #card-info>{{ $t('main.cards.bridge.description') }}</template>
          <template #card-button>
            <Button v-if="$chain.is('huobi')" disabled>
              {{ $t('main.soon') }}
            </Button>
            <Button to="new-bridge" disabled>
<!--              {{ $t('main.cards.bridge.button') }}-->
              COMING SOON
            </Button>
          </template>
        </MainCard>
      </div>
      <divs class="card">
        <MainCard class="index-page-card--small index-page-card--shake">
          <template #card-title>{{ $t('main.cards.shake_blender.title') }}</template>
          <template #card-info>{{ $t('main.cards.shake_blender.description') }}</template>
          <template #card-button>
            <Button
              disabled
              v-if="chainId === 56 || chainId === 97 || chainId === 128 || $chain.is('avalanche')"
            >
              {{ $t('main.soon') }}
            </Button>
            <Button to="blender" :disabled="$chain.is('avalanche')" v-else>
              {{ $t('main.cards.shake_blender.button') }}
            </Button>
          </template>
        </MainCard>
      </divs>
    </div>
  </div>
</template>

<style lang="scss">
.index-page--small {
  display: flex;
  max-width: 100%;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  div {
    box-sizing: border-box;
  }
  .special {
    width: 50%;
    padding: 3rem 2rem;
    box-sizing: border-box;
    .index-page-card--special {
      flex-direction: row;
    }
  }
  .cards {
    width: 50%;
    .card {
      padding: 1rem;
      width: 50%;
    }
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .carousel {
    width: 50%;
    padding: 2rem;
  }
  .chart {
    width: 50%;
    padding: 3rem;
  }
  @media screen and (max-width: $breakpoint-lg) {
    .carousel {
      width: 100%;
      order: 1;
    }
    .chart {
      width: 60%;
      order: 3;
    }
    .special {
      width: 100%;
      order: 2;
      .index-page-card--special {
        flex-direction: row;
      }
    }
    .cards {
      width: 100%;
      order: 4;
      .card {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    .special {
      display: flex;
      .btn {
        width: 100%;
        max-width: 100%;
      }
      .index-page-card--special {
        height: auto;
        flex-direction: column;
        .index-page-card__icon {
          width: 100%;
          max-height: 57rem;
          img {
            width: 100%;
            height: auto;
          }
        }
        .index-page-card__content {
          width: 100%;
          height: auto;
        }
      }
    }
    .chart {
      width: 100%;
      order: 3;
    }
    .cards {
      width: 100%;
      .card {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    .special {
      .btn {
        width: 100%;
        max-width: 100%;
      }
      .index-page-card--special {
        .index-page-card__icon {
          width: 100%;
          height: auto;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .chart {
      display: none;
    }
    .cards {
      width: 100%;
      .card {
        width: 100%;
      }
    }
  }
}
.index-page {
  @media screen and (max-width: $breakpoint-sm) {
    padding-top: 0;
  }

  h4 {
    text-transform: uppercase;
  }
}

a.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.banners-carousel {
  height: auto !important;
  border-radius: 3rem;

  .v-carousel__item {
    height: auto !important;
    border-radius: 3rem;
    overflow: hidden;
  }
  .v-carousel__controls {
    bottom: 1rem;
    height: 4rem;
    @media screen and (max-width: $breakpoint-md) {
      bottom: 1rem;
    }
  }
  .v-carousel__controls__item {
    margin: 0 0.2rem;
  }
  .v-carousel__controls button {
    height: 3rem;
    width: 3rem;
  }
  .theme--dark.v-image {
    border-radius: 3rem;
    overflow: hidden;
  }
  .theme--dark.v-btn.v-btn--icon.v-size--small {
    color: #C3C4D3;
    opacity: 30%;
    .v-icon {
      font-size: 3rem !important;
    }
    &.v-btn--active {
      opacity: 100%;
      &:before {
        display: none;
      }
    }
  }
}
</style>
