<script>
export default {
  name: 'MainCard',
};
</script>

<template>
  <div class="index-page-card main-block">
    <div class="index-page-card__icon">
      <slot name="card-icon"/>
    </div>
    <div class="index-page-card__content">
      <h5 class="index-page-card__coming-soon">
        <slot name="badge"/>
      </h5>
      <h4 :class="['index-page-card__ttl', this.$slots.badge ? 'faded' : '']">
        <slot name="card-title"/>
      </h4>
      <p class="index-page-card__info" v-if="$slots['card-info']">
        <slot name="card-info"/>
      </p>
      <slot name="card-button"/>
    </div>
    <div class="index-page-card__link">
      <slot name="card-button"/>
    </div>
  </div>
</template>

<style lang="scss">
$urls-icon-bridge: "~@/assets/img/index-page/icon-bridge.svg";
$urls-icon-booster: "~@/assets/img/index-page/icon-booster.svg";
$urls-icon-interstellar: "~@/assets/img/index-page/icon-interstellar.svg";
$urls-icon-shake: "~@/assets/img/index-page/icon-shake.svg";
$urls-icon-bridge-colored: "~@/assets/img/index-page/icon-bridge-colored.svg";
$urls-icon-booster-colored: "~@/assets/img/index-page/icon-booster-colored.svg";
$urls-icon-interstellar-colored: "~@/assets/img/index-page/icon-interstellar-colored.svg";
$urls-icon-shake-colored: "~@/assets/img/index-page/icon-shake-colored.svg";
@mixin maincard-bg($name) {

  $url: "~@/assets/img/index-page/#{$name}-bg.png";
  $url-binance: "~@/assets/img/binance/index-page/#{$name}-bg.png";
  $url-huobi: "~@/assets/img/huobi/index-page/#{$name}-bg.png";
  background: url($url) no-repeat center right / contain,;
  @media screen and (max-width: $breakpoint-sm) {
    background: url($url) no-repeat 5rem -3rem / contain;
  }
  body.binance & {
    background:
      url($url-binance) no-repeat center right / contain,
      $b-main-item-bg;
    @media screen and (max-width: $breakpoint-sm) {
      background:
        url($url-binance) no-repeat 5rem -3rem / contain,
        $b-main-item-bg;
    }
  }
  body.huobi & {
    background:
      url($url-huobi) no-repeat center right / contain,
      $h-main-item-bg;
    @media screen and (max-width: $breakpoint-sm) {
      background:
        url($url-huobi) no-repeat 5rem -3rem / contain,
        $h-main-item-bg;
    }
  }
}
body.avalanche .index-page-card {
  &.index-page-card--small {
    .index-page-card__link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .btn {
        font-size: 0;
        background: none;
      }
      a {
        font-size: 0;
        width: 100%;
        height: 100%;
      }
    }
    .btn {
      border: none;
      box-shadow: none;
      text-align: left;
      padding: 0;
      margin: 1.2em 0 0 0;
      font-size: 1.8rem;
      color: $main-pink !important;
      background: unset;
      &.disabled {
        color: #68748b !important;
      }
      &:disabled {
        color: #68748b !important;
      }
      &:hover {
        background: unset;
        box-shadow: none;
      }
    }
  }
  &--interstellar.index-page-card--small {
    background: none;
  }
  &--booster.index-page-card--small {
    background: none;
  }
  &--bridge.index-page-card--small {
    background: none;
  }
  &--shake.index-page-card--small {
    background: none;
  }
}
body.binance .index-page-card {
  &.index-page-card--small {
    .index-page-card__link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .btn {
        font-size: 0;
      }
      a {
        font-size: 0;
        width: 100%;
        height: 100%;
      }
    }
    .btn {
      border: none;
      box-shadow: none;
      text-align: left;
      padding: 0;
      margin: 1.2em 0 0 0;
      font-size: 1.8rem;
      color: $main-pink;
      background: unset;
      &:hover {
        background: unset;
        box-shadow: none;
      }
    }
  }
  &--interstellar.index-page-card--small {
    background: none;
  }
  &--booster.index-page-card--small {
    background: none;
  }
  &--bridge.index-page-card--small {
    background: none;
  }
  &--shake.index-page-card--small {
    background: none;
  }
}
.index-page-card {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  overflow: hidden;
  width: 100%;
  height: 36.4rem;
  padding: 8.4rem 17.8rem 4.7rem 3.2rem;
  background-size: 100% auto;
  background-position: left top;
  background-repeat: no-repeat;

  @media screen and (max-width: $breakpoint-md) {
    height: 50rem;
  }
  @media screen and (max-width: $breakpoint-sm) {
    height: 47rem;
    padding: 7rem 2.8rem 4.8rem;
  }
  &--promo {
    @include maincard-bg(promo);
    background-position: right top;
    .index-page-card__ttl{
      width: 23rem;
    }
    .index-page-card__info{
      font-size: 2rem;
    }

    @media screen and (max-width: $breakpoint-sm) {
      background-position: 5rem 0 !important;
    }
  }
  &--interstellar {
    @include maincard-bg(interstellar);
  }
  &--shake {
    @include maincard-bg(shake);
  }
  &--gravity {
    @include maincard-bg(gravity);
  }
  &--shadow {
    @include maincard-bg(shadow);
  }

  &--game{
    @include maincard-bg(game);
  }

  &--index-token {
    @include maincard-bg(index);
  }
  &--nft {
    /* TODO: Ask for design */
    @include maincard-bg(nft);
  }
  &--nft-hero {
    @include maincard-bg(nft-hero);
  }
  &--synthetic {
    @include maincard-bg(synthetic);
  }
  &--spacelend {
    @include maincard-bg(spacelend);
  }
  &--spacecrew {
    @include maincard-bg(spacecrew);
  }
  &--starter {
    @include maincard-bg(starter);
  }
  &--booster {
    @include maincard-bg(booster);
  }
  &--bridge {
    @include maincard-bg(bridge);
  }
  &--nft-sale {
    @include maincard-bg(nft-sale);
  }
  &--ido {
    @include maincard-bg(ido);
  }
  &--calendar {
    @include maincard-bg(calendar);
  }
  &--milk-pool {
    @include maincard-bg(milk-pool);
  }
  &--shake-pool {
    @include maincard-bg(shake-pool);
  }
  &--locked {
    padding: 8.4rem 9.8rem;
    text-align: center;
    opacity: .6;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $breakpoint-sm) {
      padding: 7rem 2.8rem 4rem;
    }
  }
  &__img {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
  }
  &__ttl {
    min-height: 7.2rem;
    font-size: 3rem;
    margin-bottom: 2rem;
    @media screen and (max-width: $breakpoint-lg) {
      font-size: 2.5rem;
    }
    @media screen and (max-width: $breakpoint-md) {
      font-size: 4rem;
    }
    @media screen and (max-width: $breakpoint-sm) {
      font-size: 3.4rem;
      width: 80%;
    }

    &.faded {
      opacity: 0.6;
    }
  }
  &__info {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5em;
    min-height: 7.4rem;
    @media screen and (max-width: $breakpoint-md) {
      font-size: 2.4rem;
    }
    @media screen and (max-width: $breakpoint-sm) {
      font-size: 2rem;
      width: 80%;
    }
  }
  &__coming-soon {
    font-size: 1.6rem;
    text-transform: uppercase;
    position: absolute;
    top: 6rem;
    @media screen and (max-width: $breakpoint-sm) {
      top: 4.5rem;
    }
  }
  .btn {
    margin-top: auto;
    width: 100%;
    @media (max-width: $breakpoint-sm) {
      padding: 1.1em 3em;
    }
  }
  &--special {
    border-radius: 1.5rem;
    border: 1px solid #F2F2F2;
    background: #FCFDFE;
    box-shadow: -1px -1px 2px 0px rgba(181, 155, 155, 0.20) inset,
    2px 2px 6px 0px #FFF inset, -10px -10px 15px 0px #FFF,
    2px 2px 20px 0px rgba(212, 202, 205, 0.20), 10px 10px 20px 0px rgba(222, 222, 222, 0.25);
    padding: 2rem;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    .index-page-card__link {
      display: none;
    }
    .index-page-card__ttl {
      min-height: auto;
      text-transform: uppercase;
    }
    .index-page-card__content {
      width: 50%;
      height: 100%;
      padding: 3rem;
      .btn {
        border-radius: 3.0625rem;
        background: var(--new-gradient_btn, linear-gradient(270deg,
          #FF7979 0%, #FF9F9F 101.76%)) !important;
        box-shadow: none !important;
        font-size: 1.5rem;
        color: #F8FCFD;
        max-width: 25rem;
      }
    }
    .index-page-card__icon {
      width: 50%;
      height: 100%;
      border-radius: 1.5rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .index-page-card__icon__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &--small {
    border-radius: 1.5rem;
    border: 1px solid #F2F2F2;
    background: #FFF;
    box-shadow: -1px -1px 2px 0px rgba(181, 155, 155, 0.20) inset,
    2px 2px 6px 0px #FFF inset, -10px -10px 15px 0px #FFF,
    2px 2px 20px 0px rgba(212, 202, 205, 0.20),
    10px 10px 20px 0px rgba(222, 222, 222, 0.25);
    padding: 3rem 2rem;
    height: 17rem;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    .index-page-card__link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .btn {
        font-size: 0;
      }
      a {
        font-size: 0;
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      .index-page-card__ttl {
        color: $main-pink;
      }
      &.index-page-card--interstellar {
        .index-page-card__content {
          &:before {
            background-image: url($urls-icon-interstellar-colored);
          }
        }
      }
      &.index-page-card--booster {
        .index-page-card__content {
          &:before {
            background-image: url($urls-icon-booster-colored);
          }
        }
      }
      &.index-page-card--bridge {
        .index-page-card__content {
          &:before {
            background-image: url($urls-icon-bridge-colored);
          }
        }
      }
      &.index-page-card--shake {
        .index-page-card__content {
          &:before {
            background-image: url($urls-icon-shake-colored);
          }
        }
      }
    }
    &.index-page-card--interstellar {
      .index-page-card__content {
        &:before {
          background-image: url($urls-icon-interstellar);
        }
      }
    }
    &.index-page-card--booster {
      .index-page-card__content {
        &:before {
          background-image: url($urls-icon-booster);
        }
      }
    }
    &.index-page-card--bridge {
      .index-page-card__content {
        &:before {
          background-image: url($urls-icon-bridge);
        }
      }
    }
    &.index-page-card--shake {
      .index-page-card__content {
        &:before {
          background-image: url($urls-icon-shake);
        }
      }
    }
    .index-page-card__content {
      position: relative;
      width: 60%;
      height: 100%;
      &:before {
        content: '';
        position: absolute;
        left: -13rem;
        height: 10rem;
        width: 10rem;
        background-position: center;
        background-size: contain;
      }
    }
    .index-page-card__ttl {
      font-size: 2.3rem;
      min-height: auto;
      margin: 0;
    }
    .index-page-card__info {
      display: none;// TODO: change
    }
    .btn {
      border: none;
      box-shadow: none;
      text-align: left;
      padding: 0;
      margin: 1.2em 0 0 0;
      font-size: 1.8rem;
      color: $main-pink;
      background: unset;
      &:hover {
        background: unset;
        box-shadow: none;
      }
    }
  }
}
</style>
